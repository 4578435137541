// Relation
import { useEffect, useMemo, useState } from "react";

// import copy from 'copy-to-clipboard'

import {
    Factory,
    Router,
    MPair,
    BasePairs,
    SwapDefault
} from "../../contract/swap"

import { useWeb3, initWeb3, BN, multiCalls, SendOn, utils, ZERO_ADDRESS, ERC20, SendLocalOn} from '../../web3'

// import initAsyncData from '../initAsyncData'

import useInput from '../useInput'
import useSendButton from "../useSendButton"

// import {getUrlParams, debounce} from "../../utils"
import {getAmountOut} from "../../utils/swap"

import useSlippage from '../useSlippage'

// swap 交易使用
// 1. 获取交易对
// 2. 计算价格
// 3. 订单薄【待定】


// 根据 币对获取路径 只找出所有路径，查找最优在下一个函数
// paths: [plAddress, ...]
// tokenNames: [token0Name, token1Name...]
// isRes: [ture, ...]
// const INIT_PAIR = {
//     tokenInBalance: 0,
//     tokenOutBalance: 0,
//     tokenIn: [],
//     tokenOut: [],
//     paths: [],
//     tokenNames: [],
//     isRes: []
// }

async function initPair(tokenIn, tokenOut) {
    const factory = Factory()
    const basePairs = BasePairs()

    // const t0 = ERC20(tokenIn[0])
    // const t1 = ERC20(tokenOut[0])
    let allPaths = {
        direct: factory.methods.getPair(tokenIn[0], tokenOut[0]),
        [tokenIn[2]]: {},
        [tokenOut[2]]: {}
    }

    let tokenInStorage = allPaths[tokenIn[2]]
    let tokenOutStorage = allPaths[tokenOut[2]]
    const tokenInAddress = tokenIn[0].toLowerCase()
    const tokenOutAddress = tokenOut[0].toLowerCase()
    basePairs.forEach(item => {

        let [addr,, name] = item

        addr = addr.toLowerCase()
        
        if (addr !== tokenInAddress) {
            tokenInStorage[name] = factory.methods.getPair(tokenInAddress, addr)
        }
        if (addr !== tokenOutAddress) {
            tokenOutStorage[name] = factory.methods.getPair(tokenOutAddress, addr)
        }
    })

    // console.log('allPaths1', allPaths)

    allPaths = await multiCalls(allPaths)
    
    // console.log('allPaths', allPaths)

    let paths = []
    let tokenNames = []
    let isRes = []
    // // 1. 拉取 base l
    if (allPaths.direct !== ZERO_ADDRESS) {
        paths.push([allPaths.direct])
        tokenNames.push([])
        isRes.push([tokenInAddress * 1 > tokenOutAddress * 1 ? true : false])
    }

    tokenInStorage = allPaths[tokenIn[2]]
    tokenOutStorage = allPaths[tokenOut[2]]
    for (let key in tokenInStorage) {
        const lpIn = tokenInStorage[key]
        const lpOut = tokenOutStorage[key]
        if (lpIn !== ZERO_ADDRESS && lpOut && lpOut !== ZERO_ADDRESS) {
            paths.push([lpIn, lpOut])
            tokenNames.push([key])
            isRes.push([
                tokenInAddress * 1 > key * 1 ? true : false,
                key * 1 > tokenOutAddress * 1 ? true : false,
            ])
        }
    }
    

    return {
        tokenIn,
        tokenOut,
        paths,
        tokenNames,
        isRes
        // paths
    }
}

// 计算最优路径
// paths: 可选lp
// amountIn: 输入数量
// 每个区块更新后，刷新这里
// 返回最优路径就行
const INIT_BAST_PATH = {
    bestPath: [],
    bestTokenNames: [],
    lpReserves: [[]],
    bestRes: [],
    getOut: () => 0

}
async function getBestPath(amountIn, paths, isRes, tokenNames) {
    /// 1. 获取 lp reserve
    let lpReserves = await multiCalls(paths.map(item => item.map(v => MPair(v).methods.getReserves())))
    // console.log(lpReserves)
    let maxOut = 0;
    // 最佳路径 lp 地址
    let bestPath = []
    let bestTokenNames = []
    // 最佳路径 reserve
    let bestRes = []

    lpReserves = lpReserves.map((item, index) => {
        const isRe = isRes[index]

        let out = amountIn
        const res = item.map((v, i) => {
            let resIn =  BN(v[0]).div(1e18).toString(10)
            let resOut = BN(v[1]).div(1e18).toString(10)
            if ( isRe[i] ) [resIn, resOut] = [resOut, resIn]
            out = getAmountOut(out, resIn, resOut, 0.005);
            return [resIn, resOut]
        })
        if (out > maxOut) {
            maxOut = out
            bestPath = paths[index]
            bestTokenNames = tokenNames[index]
            bestRes = res
        }
        return res
    })

    /// getAomountOut
    const getOut = (amountIn, slippage = 0.005, _isRes = false) => {
        let amountOut = BN(amountIn)

        if (!_isRes) {
            for (let i = 0; i < bestRes.length; i++) {
                const [resIn, resOut] = bestRes[i]
                amountOut = getAmountOut(amountOut, resIn, resOut, slippage);
            }
        } else {
            for (let i = bestRes.length - 1; i >= 0; i--) {
                const [resIn, resOut] = bestRes[i]
                amountOut = getAmountOut(amountOut, resOut, resIn , slippage);
            }
        }
       
        return amountOut
    }

    return {
        getOut,
        bestPath,
        bestTokenNames,
        bestRes,
        lpReserves
    }
}


/// 后去 余额
async function getBalance(account, tokenIn, tokenOut) {
    if (!tokenIn.length || !tokenOut.length) return {
        tokenIn: 0,
        tokenOut: 0
    }
    const t0 = ERC20(tokenIn[0])
    const t1 = ERC20(tokenOut[0])
    const calls = await multiCalls({
        tokenInBalance: t0.methods.balanceOf(account),
        tokenOutBalance: t1.methods.balanceOf(account)
    })

    return {
        tokenIn: BN(calls.tokenInBalance).div(10 ** tokenIn[1]).toString(10),
        tokenOut: BN(calls.tokenOutBalance).div(10 ** tokenOut[1]).toString(10)
    }
}

// 获取 pair
// 此类 hook 作用是手动请求数据，不 State 数据
// 将 status 解藕，用于请求时的 loading 状态
// return 各类接口，方便 其他 hook 调用时获取数据
// api 接口为纯函数，不依赖 state
export function useGetPair() {
    // const [pair, setPair] = useState(INIT_PAIR)
    // const [bast, setBast] = useState(INIT_BAST_PATH)

    const [status, setLoading] = useState({
        loading: false,
        erre: null
    })

    const success = () => setLoading({loading: false, error: null})
    const fail = (error) => setLoading({loading: false, error})
    const pending = () => setLoading({loading: true, error: null})
    // 切换 token 时调用
    const getPair = async (tokenIn, tokenOut) => {
        if (!tokenIn.length || !tokenOut.length) return true
        // pending()
        try {
            const pair = await initPair(tokenIn, tokenOut)
            // setPair(pair)
            // success()
            return [null, pair]
        } catch (error) {
            console.log(error)
            // fail(error.message)
            return [error.message, null]
        }
    }

    // 更新区块 可以 切换 token 时使用
    const getBastReserve = async (_pair) => {
        // pair
        // pending()
        const { paths, isRes, tokenNames } = _pair
        if (!paths.length) return ["no path", null]
        try {
            const bast = await getBestPath(1e18, paths, isRes, tokenNames)
            // setBast(bast)
            // success()
            return [null, bast]
        } catch (error) {
            console.log(error)
            // fail(error.message)
            return [error.message, null]
        }
    }

    return {
        // pair,
        getPair,
        getBastReserve,
        status,
        success,
        pending,
        fail
        // bast
    }
}



// 交易

// 交易记录
export function useSwap() {
    const { getBlockNumber, account } = useWeb3()

    // const [bast, setBast] = useState(INIT_BAST_PATH)
    const [tBalance, setTbalance] = useState([0,0])
    const amountIn = useInput('', { type: 'number' })

    const [isRes, setIsRes] = useState(false)

    const [tokens, setTokens] = useState(() => {
        const [tokenIn, tokenOut] = SwapDefault();
        // console.log(tokenIn, tokenOut)
        return {
            tokenIn,
            tokenOut,
            bast: INIT_BAST_PATH
        }
    })

    const bast = tokens.bast

    /// 整理交易
    const { getPair, getBastReserve, status, pending, success, fail: getFail } = useGetPair()
    const { slippage: { value: slippage }, timeScends } = useSlippage()

    const {
        button,
        setButtonText,
        loading,
        init: initButton,
        txError,
        fail,
        successful
    } = useSendButton('Swap')
   
    const {
        tokenIn,
        tokenOut,
    } = useMemo(() => {
        let tokenIn = tokens.tokenIn
        let tokenOut = tokens.tokenOut
        if (isRes) {
            [tokenIn, tokenOut] = [tokenOut, tokenIn];
        }
        return {
            tokenIn,
            tokenOut
        }
    }, [tokens.tokenIn[0], tokens.tokenOut[0], isRes])

    const {
        tokenInBalance,
        tokenOutBalance
    } = useMemo(() => {
        let tokenInBalance = tBalance[0]
        let tokenOutBalance = tBalance[1]
        if (isRes) {
            [tokenInBalance, tokenOutBalance] = [tokenOutBalance, tokenInBalance]
        }
        return {
            tokenInBalance,
            tokenOutBalance
        }
    }, [tBalance[0], tBalance[1], isRes])

    
    // 设置token
    const resetToken = async (tokenIn, tokenOut) => {
        // console.log({tokenIn, tokenOut})
        pending()
        // if ( tokens.tokenIn[0] !== tokenIn[0] || tokens.tokenOut[0] !== tokenOut[0] ) {
            console.log(tokenIn, tokenOut, 'tokenIn, tokenOut')

            const [err, pair] = await getPair(tokenIn, tokenOut)
            console.log(pair, 'pair')
            if (err) return getFail(err)
            const [rErr, bast] = await getBastReserve(pair)
            console.log([rErr, bast], ' [rErr, bast]')
            if (rErr) return getFail(rErr)
            setTokens({
                tokenIn,
                tokenOut,
                bast
            })
        // }
        success()
    }

    // const bastRes = async () =>

    const blockNubmer = getBlockNumber()
    useEffect(() => {
        // console.log(tokens.tokenIn[0], tokens.tokenOut[0])
        resetToken(tokens.tokenIn, tokens.tokenOut)
    }, [blockNubmer, tokens.tokenIn[0], tokens.tokenOut[0]])

    useEffect(() => {
        const updateBalance = async () => {
            const { tokenIn, tokenOut } = await getBalance(account, tokens.tokenIn, tokens.tokenOut)
            setTbalance([tokenIn, tokenOut])
        }
        updateBalance()
    }, [blockNubmer, account, tokens.tokenIn[0], tokens.tokenOut[0]])

    // 切换交易方向
    const switchRes = () => setIsRes(v => !v)

    const {
        amountOut, 
        amountOutMin
    } = useMemo(() => {
        if (amountIn.value === '' ) {
            return {
                amountOut: 0,
                amountOutMin: 0
            }
        }
        const amountOut = bast.getOut(amountIn.value, 0.005, isRes)
        const amountOutMin = BN(bast.getOut(amountIn.value, slippage / 100 , isRes)).dp(6,1).toString()
        return {
            amountOut,
            amountOutMin
        }
    }, [amountIn.value, isRes, bast.getOut, slippage])

    const exChange = async () => {
        loading("Pending...")
        const route = Router()
        const amountInWei = BN(amountIn.value).mul(10 ** tokenIn[1]).dp(0,1).toString(10)
        const amountOutWei = BN(amountOutMin).mul(10 ** tokenOut[1]).sub(1).dp(0,1).toFixed(0)

        SendLocalOn(
            route.methods.swapExactTokensForTokensSupportingFeeOnTransferTokens(
                amountInWei,
                amountOutWei,
                [
                    tokenIn[0],
                    tokenOut[0]
                ],
                account,
                (~~(new Date() / 1000)) + timeScends.value * 60
            ),
            {
                seed: 5,
                cont: `Swap ${Math.floor(amountIn.value * 1000) / 1000} ${tokenIn[2]} for ${tokenOut[2]}`,
                signDone: initButton,
                cancel() {
                    fail('Swap Cancel')
                },
                fail(err) {
                    // console.log(err.message.match(/INSUFFICIENT_OUTPUT_AMOUNT1/),'err.message')
                    if (err.message.match(/INSUFFICIENT_OUTPUT_AMOUNT/)) {
                        txError("Trading slippage set too small")
                    } else {
                        txError(err.message)
                    }
                    fail('Swap fail')
                },
                confirm () {
                    amountIn.onChange(0)
                    successful('Swap successful')
                }
            }
        )
    }

    return {
        tokenIn,
        tokenOut,
        amountIn,
        amountOut,
        amountOutMin,
        isRes,
        switchRes,
        resetToken,
        slippage,
        tokenInBalance,
        tokenOutBalance,
        status,
        approve: {
            fastSign: true,
            coins: [
                [ ...tokenIn, amountIn.value, true ],
                // [ ...tokenOut, 100, true ]
            ],
            loading: button.loading,
            children: button.children,
            then: exChange,
            sender: Router()._address,
            // disabled: !!swapErr
        }
    }
}


