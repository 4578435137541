/*
 * @Author: sam
 * @Date: 2021-06-28 01:31:38
 * @LastEditTime: 2021-06-30 22:30:52
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /bagels.2/src/hook/useDialog.js
 */

import { useContext, useState, useCallback, memo } from 'react'

import {DialogContext, DialogContext1} from '../context'

import {
    Dialog,
} from '../components'

function useDialog(content, dialogProps) {
    const {
        open: openDialog,
        close,
    } = useContext(DialogContext)
    const open = contents => {
        openDialog( contents && !contents.target ? contents : content, dialogProps)
    }
    return {
        close,
        open
    }
}

export default useDialog

export function useDialog1(content, dialogProps) {
    const {
        open: openDialog,
        close,
    } = useContext(DialogContext1)
    const open = contents => {
        openDialog( contents && !contents.target ? contents : content, dialogProps)
    }
    return {
        close,
        open
    }
}

export function useDialogContext() {
    const [opened, setOpen] = useState(false)
    const closeDialog = useCallback(() => {
        setOpen(false)
    }, [])
    const open = useCallback(() => {
        setOpen(true)
    }, [])
    return {
        opened,
        open,
        onClose: closeDialog
    }
}



export const Dialog2 = memo(({opened, onClose,children, closeButton, ...other}) => (
    <Dialog
        fullWidth
        maxWidth='sm'
        scroll='body'
        {...other}
        onClose={onClose}
        aria-labelledby="simple-dialog-title"
        open={opened}
    >
        {children}
    </Dialog>
))