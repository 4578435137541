
import { memo } from 'react';

import { useTranslation } from 'react-i18next';

import styled from 'styled-components';

import {
    // HashRouter as Router,
    // Route,
    // Link,
    useHistory
} from "react-router-dom";

import {
    Card,
    Container,
    TextMD,
    TextM,
    TextSM,
    TextGL,
    WhiteSpace,
    WingBlank,
    Input,
    ImgIcon,
    Button,
    Hr,
    HrCol,
    Icon,
    TextLinear,
    FlexBlock,
    Progress,
    // Hot,
    Tooltip,
    // APR,
    NoData,
    Hot,
    TipsImg
} from '../../components'


// import StakeSeed from './StakeSeed'
// import StakeDialog from '../StakeDialog'
// import Swap, {AddLiquidity} from './Swap'

import useBalance from '../../hook/pages/useBalance'

// dialog 
// import useDialog, {useDialogContext, Dialog2} from '../../hook/useDialog'
// import useInput from '../../hook/useInput'

// import useCopy from '../../hook/useCopy'

import { wFormatInt, splitNumber, BN } from 'web3-sam/dist/BigNumber';
import {utils} from 'web3-sam'
// import {getAmountIn} from "../../utils/swap"

import {
    usePurchaseLpPool,
    useBrun,
} from "../Dialog"

import {
    useLpPoolData,
    useLpClaim
} from "../../hook/pages/useLpPool"

// import SvgCircle,{CircleArr} from '../../pageComponents/SvgCircle'

// import {useSmiCountdown} from '../../hook/useCountdown'

// import {
//     useStakeF,
//     useClaimF2,
//     useClaimF1
// } from '../../hook/pages/useStakeF'

const { shortAddress } = utils

function Claim() {
    return <Button size='1' {...useLpClaim()}/>
}
const ClaimMemo = memo(Claim)

// function ClaimF2() {
//     return <Button size='0' {...useClaimF2()}/>
// }
// const ClaimF2Mome = memo(ClaimF2)


function LpPool() {
    const openPurchase = usePurchaseLpPool()
    const openBurn = useBrun()
    const bal = useBalance()
    const {
        daily,
        claim,
        revenue, // total claimed value
        myPower, // 不含 boost
        powerBoost,
        powerInvest, // 总投资金额
        share, // 不含 boost
        shareBoost,
        stakeList,
    } = useLpPoolData()
    
    const oracle = bal.oracle(["CDA","USDT"])
    const claimV = oracle.price * claim
    const {t} = useTranslation()
    return (
        <Container align='left'>
            <TextMD b='800'><ImgIcon src='./icon/power.png'/> {t("Pools")}</TextMD>
            <WhiteSpace />
            <Card>
                <Daily>
                    <TextSM color='1' style={{fontSize: '10px'}}>{t("Daily Output")}</TextSM>
                    <br />
                    <TextM b='800' color='1' style={{fontSize: '16px',lineHeight: "18px"}}>{daily} CDA</TextM>
                </Daily>
                <TextMD>Power Pool</TextMD>
                <br />
                <TextSM color='2' style={{textAlign: 'center'}}>{t("Buy Power to earn CDA.")}</TextSM>
                <WhiteSpace />
                <Hr />
                <WhiteSpace />
                <FlexBlock flex>
                    <div>
                        <TextSM color='2' style={{textAlign: 'center'}}>{t("Mined")} CDA</TextSM>
                        <br />
                        <TextLinear>{claim}</TextLinear><TextSM color='2' style={{marginLeft:'4px'}}> ≈ $ {wFormatInt(claimV)}</TextSM>
                        <br />
                        <TextSM color='2'>{t("Total")} {t("Revenue")} $ <TextSM>{revenue}</TextSM></TextSM>
                        <br />
                        <TextSM color='2'>{t("Total")} {t("Invest")} $ <TextSM>{wFormatInt(powerInvest)}</TextSM></TextSM>
                    </div>
                    <ClaimMemo />
                </FlexBlock>
                {/* <FlexBlock flex>
                    <TextSM color='2'>Total Revenue $ <TextSM>0.00</TextSM></TextSM>
                    <TextSM color='2'>Spent $ <TextSM>0.00</TextSM></TextSM>
                </FlexBlock> */}
                <WhiteSpace />
                {/* <Hr />
                <WhiteSpace /> */}
                <FlexBlock flex>
                    <Card align='center' decorate='9' noLogo>
                        {/* <TextSM color='2'>My Power <Hot>x{powerBoost}</Hot></TextSM> */}
                        <TextSM color='2'>{t("My")} {t("Power")}</TextSM>
                        <br />
                        {/* excloud boost */}
                        <TextLinear size='1'>{wFormatInt(myPower)}</TextLinear>
                        <br />
                        <TextSM color='2'>{t("Boost")} <TextSM>x{powerBoost}</TextSM></TextSM>
                        {/* <br />
                        <TextSM color='2'>Invest $ <TextSM>{wFormatInt(powerInvest)}</TextSM></TextSM> */}
                    </Card>
                    <WingBlank />
                    <Card align='center' decorate='9' noLogo>
                        <TextSM color='2'>{t("Share")} {t("Power")}</TextSM>
                        <br />
                        <TextLinear size='1'>{wFormatInt(share)}</TextLinear>
                        <br />
                        <TextSM color='2'>{t("Boost")} <TextSM>x{shareBoost}</TextSM></TextSM>
                    </Card>
                </FlexBlock>
                {/* <FlexBlock flex>
                    <div style={{flex: 21, textAlign:'left'}}>
                        <TextSM color='2'>Boost</TextSM>
                        <br />
                        <TextM b='800' style={{lineHeight:'1.8rem'}}>x1</TextM>
                    </div>
                    <HrCol style={{margin: '0px 14px'}} />
                    <div style={{flex: 79}}>
                        <FlexBlock flex>
                            <TextSM color='2'>My Power</TextSM>
                            <TextSM>0</TextSM>
                        </FlexBlock>
                        <FlexBlock flex>
                            <TextSM color='2'>My Share</TextSM>
                            <TextSM>0</TextSM>
                        </FlexBlock>
                    </div>
                </FlexBlock> */}
                <WhiteSpace />
                <FlexBlock flex>
                    <Button status='1' style={{flex: 48}} onClick={openPurchase}>{t("Buy")} {t("Power")}</Button>
                    <div style={{flex: 5}}></div>
                    <Button status='7' style={{flex: 48}} onClick={openBurn}>{t("Burn")}</Button>
                </FlexBlock>
            </Card>
            <WhiteSpace />
            <Card>
                <TextM b='800'>{t("My")} {t("Power")} {t("List")}</TextM>
                <WhiteSpace />
                <Hr />
                <WhiteSpace />
                {
                    stakeList.length === 0 ? <NoData />:
                    stakeList.map(v => (
                        // <div key={v.id}>
                        //     <FlexBlock flex>
                        //     </FlexBlock>
                        // </div>
                        <div key={v.id} style={{marginBottom:'8px'}}>
                            <Hot style={{flex: 1}}># 0{v.id}</Hot>
                            <FlexBlock flex>
                                <div style={{flex: 8, margin:'0px 0px'}}>
                                    <FlexBlock flex>
                                        {/* not shrink */}
                                        <TextSM color='2'>{t("My")} {t("Power")} <TextSM>{wFormatInt(v.stakeUsdt)}</TextSM></TextSM>
                                        <TextSM color='2'> <TextSM>{v.stakedDay}</TextSM> D</TextSM>
                                    </FlexBlock>
                                    <FlexBlock flex>
                                        <TextSM color='2' style={{marginRight:'12px'}}>{t("Surplus")} </TextSM> <Progress value={v.surplus}/> <TextSM style={{marginLeft:'12px'}}>{v.surplus}%</TextSM>
                                    </FlexBlock>
                                    
                                    {/* <TextSM color='2'>Surplus <TextSM>100</TextSM> %</TextSM> */}
                                </div>
                                {/* <Button status='0' size='0' style={{flex: 2}}>0 Day</Button> */}
                            </FlexBlock>
                            <WhiteSpace />
                            <Hr />
                        </div>
                    ))
                }
                {/* <NoData /> */}
            </Card>
        </Container>
    )
}
export default LpPool

export function LpPoolImport() {
    const hisory = useHistory()
    const bal = useBalance()
    const {
        daily,
        claim,
        revenue, // total claimed value
        myPower, // 不含 boost
        powerBoost,
        powerInvest, // 总投资金额
        share, // 不含 boost
        shareBoost,
        stakeList,
    } = useLpPoolData()
    
    const oracle = bal.oracle(["CDA","USDT"])
    const claimV = oracle.price * claim
    const {t} = useTranslation()
    return (
        <Card>
            <TextMD b='800' style={{
                verticalAlign: 'sub',
                marginRight:'6px'
            }}>{t("Power")} {t("Pool")} </TextMD>
            {/* <Hot size='16'>X2.5</Hot> */}
            {/* <TextMD>
                Lp Pool 
                <Hot>
                    <TextSM>Daily Output</TextSM>
                    <br />
                    <TextSM>100 CDA </TextSM>
                </Hot>
            </TextMD> */}
            <br />
            <TextSM color='2' style={{textAlign: 'center'}}>{t("Buy Power to earn CDA.")}</TextSM>
            {/* <br /> */}
            {/* <Hot size='16' style={{marginRight:'6px'}} t='1'>100 CDA <TextSM style={{fontSize:'10px'}} color='1'> / Day</TextSM></Hot> */}
            {/* <Daily>
                <TextSM color='1'>Daily Output</TextSM>
                <br />
                <TextM color='1' style={{lineHeight: "18px"}}>100 CDA</TextM>
            </Daily> */}
            <WhiteSpace />
            <Hr />
            <WhiteSpace />
            <FlexBlock flex>
                <div>
                    <TextSM color='2' style={{textAlign: 'center'}}>{t("Mined")} CDA</TextSM>
                    <br />
                    <TextLinear>{claim}</TextLinear><TextSM color='2' style={{marginLeft:'4px'}}> ≈ $ {wFormatInt(claimV)}</TextSM>
                    <br />
                    <TextSM color='2'>{t("Total")} {t("Revenue")} $ <TextSM>{revenue}</TextSM></TextSM>
                    <br />
                    <TextSM color='2'>{t("Total")} {t("Invest")} $ <TextSM>{wFormatInt(powerInvest)}</TextSM></TextSM>
                </div>
                <ClaimMemo />
            </FlexBlock>
            <WhiteSpace />
            <Hr />
            <WhiteSpace />
            <FlexBlock flex>
                <TextSM color='2'>{t("My")} {t("Power")} <Hot>x{powerBoost}</Hot></TextSM>
                <TextSM>{wFormatInt(myPower)}</TextSM>
            </FlexBlock>
            {/* <WhiteSpace />
            <Hr /> */}
            <WhiteSpace style={{height:'6px'}}/>
            <FlexBlock flex>
                <TextSM color='2'>{t("Share")} {t("Power")} <Hot t='1'>x{shareBoost}</Hot></TextSM>
                <TextSM>{wFormatInt(share)}</TextSM>
            </FlexBlock>
            <WhiteSpace />
            <Button w='100' status='1' onClick={() => hisory.push('/pools')}>{t("Earn")} CDA</Button>
        </Card>
    )
}


// function Daily() {
//     return (
//         <
//     )
// }

const Daily = styled.div`
    ${props => props.theme.button[props.disabled?4 :(props.status || 2)]}
    text-align: right;
    display: inline-block;
    width: auto;
    position: absolute;
    top: 16px;
    right: -2px;
    padding: 2px 6px 2px 20px;
    border-radius: 100px 0 0 100px;
`

