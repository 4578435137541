import {  useContext, createContext,useEffect, useState } from 'react'

import { ERC20 ,useWeb3, BN, multiCalls, utils, ZERO_ADDRESS, PROXY_ZERO_ADDRESS, MultiCallContract } from '../../web3'

import useToast from '../../hook/useToast'
import copy from 'copy-to-clipboard'

// import Usdt, {getUSDTAddress} from '../../contract/USDT'
import {
    TokenAll,
    WEthAddr,
    EthAddr,
    Factorys,
    Factory,
    Oracle,
    Pair
} from '../../contract/contract'


import {
    // getAmountIn,
    getAmountOut
} from '../../utils/swap'
import initAsyncData from '../../hook/initAsyncData'

// 数据结构 第二层 只能是 非对象【函数也是对象】
// 避免初始化取值遇到 undefined 报错
// 需要对象的地方，使用 function 获取，避免初始化时候报错
const INIT = {
    gasName: '--',
    oracle: () => ({
        price: 0,
        paths: [ZERO_ADDRESS],
        lp: [[0,0]]
    }),
    balance: {}, // special token => GAS & WGAS
    getOutAmount: () => 0
}

export const Context = createContext(INIT)

// // 调整 getReserves 顺序
// function sortReserve(token0Addr, token1Addr, res0, res1) {
//     const isRe = token0Addr * 1 > token1Addr * 1
//     return isRe ? [res1, res0] : [res0, res1]
// }

// _lp[token0-token1] = [factory0:[reserve0, reserve1, lpAddress],...]
const _lp = {}
const _lpPair = {}
let _onlyOnce = {}
// tokens: [token0, token1]
async function getLp(tokens, dePair) {
    const factorys = Factorys()
    // const baseTokenAddr = baseTokens[0]
    // [factory0:[], factory1:[], ...]
    const _getPair = []
    const _pIndex = {}
    factorys.forEach(factoryAddr => {
        const fac = Factory(factoryAddr)
        const _pair = []
        _onlyOnce = {}
        tokens.forEach(([token0, token1]) => {
            // if token1 is number or string, the token0 has not pair
            if ( token1 instanceof Array ) {
                const name = `${token0[2]}-${token1[2]}`
                _pIndex[_pair.length] = name;
                if ( _onlyOnce[name] ) return
                _onlyOnce[name] = true
                _pair.push(
                    fac.methods.getPair(token0[0], token1[0])
                )
            } else {
                // const name = `${token0[2]}-${dePair[2]}`
                // _lp[token0[0]] = token1
                _lp[`${token0[2]}-${dePair[2]}`] = token1
                _lp[`${dePair[2]}-${token0[2]}`] = token1 * 1 === 0 ? 0 : 1 / token1
            }
    
        })
        _getPair.push(_pair)
    })
    if ( _getPair[0].length !== 0 ) {
        const lpAddress = await multiCalls(_getPair)
        lpAddress.forEach(lpArr => {
            lpArr.forEach((lp, j) => {
                const pName = _pIndex[j]
                // const [token0Name, token1Name] = pName.split('-')
                if ( lp === ZERO_ADDRESS ) return
                if ( !_lpPair[pName] ) {
                    _lpPair[pName] = []
                }
                _lpPair[pName].push(
                    Pair(lp).methods.getReserves()
                )
            })
        })
    }

    return [
        _lp,
        _lpPair
    ]
}

async function init(account) {

    // pair 应覆盖 balance 里面的币种
    const balanceAll = TokenAll()
    const wGas = WEthAddr()
    const gas = EthAddr()
    const _oracle = Oracle()

    ////////////////// creat pair //////////////////
    // default pair & token pair
    const {DEFAULT_PAIR, CUSTOMIZE_PAIR} = _oracle
    // wgas pair
    const pairs = []
    const _pairMap = {}
    // then create customize pair
    Object.keys(CUSTOMIZE_PAIR).forEach(tokenName => {
        const customToken = CUSTOMIZE_PAIR[tokenName]
        _pairMap[tokenName] = true
        pairs.push([balanceAll.map[tokenName], customToken])
    })
    if ( DEFAULT_PAIR !== wGas[2] ) {
        pairs.push([wGas, DEFAULT_PAIR])
    }

    let _balance = [wGas]
    // first create default pair
    balanceAll.list.forEach(token => {
        const tokenName = token[2]
        if ( !(tokenName === DEFAULT_PAIR[2] || _pairMap[tokenName]) ) {
            pairs.push([token, DEFAULT_PAIR])
        }
        _balance.push(token)
    })

    ////////////////// get calls //////////////////
    const multiCall = MultiCallContract()

    const [lpPrice, lpPairCon] = await getLp(pairs, DEFAULT_PAIR)
    // console.log(
    //     lpPairCon,
    //     pairs,
    //     "lpPairConlpPairCon"
    // )
    const calls = await multiCalls({
        lpPairCon,
        balance: _balance.map(v => ERC20(v[0]).methods.balanceOf(account)),
        eth: multiCall.methods.getEthBalance(account)
    })

    const gasBalance = BN(calls.eth).div(BN(10).pow(10)).toString(10)
    const balance = {
        GAS: gasBalance,
        [gas[2]]: gasBalance
    }
    calls.balance.forEach((v, i) => {
        balance[_balance[i][2]] = BN(v).div(BN(10).pow(_balance[i][1])).toString(10)
    })

    const _balMap = {
        ...balanceAll.map,
        GAS: gas,
        WGAS: wGas,
        [wGas[2]]: wGas,
        [gas[2]]: gas
    }
    
    const _lpPrice = {...lpPrice}
    
    for(let token0_token1 in calls.lpPairCon) {
        const pairs = calls.lpPairCon[token0_token1]
        const [token0Name, token1Name] = token0_token1.split('-')
        const token0 = _balMap[token0Name]
        const token1 = _balMap[token1Name]
        const isRe = token0[0] * 1 > token1[0] * 1

        let maxRes = 0;
        let res0 = 0;
        let res1 = 0;
        let pairAddr = ZERO_ADDRESS;
        pairs.forEach((v, i) => {
            let [reserve0, reserve1] = [v[0], v[1]];
            if ( isRe ) [reserve0, reserve1] = [reserve1, reserve0];
            reserve0 = BN(reserve0).div(BN(10).pow(token0[1])).toString(10)
            reserve1 = BN(reserve1).div(BN(10).pow(token1[1])).toString(10)
            // filter max
            const _res = BN(reserve0).multipliedBy(reserve1).toString(10)
            if ( _res > maxRes ) {
                maxRes = _res
                res0 = reserve0
                res1 = reserve1
                pairAddr = lpPairCon[token0_token1][i]._parent._address
            }
        })
        _lpPrice[token0_token1] = [
            res0,
            res1,
            pairAddr
        ];
        _lpPrice[`${token1[2]}-${token0[2]}`] = [
            res1,
            res0,
            pairAddr
        ];
        if ( token1[2] === wGas[2] ) {
            _lpPrice[`GAS-${token0[2]}`] = [
                res1,
                res0,
                pairAddr
            ]
            _lpPrice[`${token0[2]}-GAS`] = [
                res0,
                res1,
                pairAddr
            ]
            _lpPrice[`${gas[2]}-${token0[2]}`] = [
                res1,
                res0,
                pairAddr
            ]
            _lpPrice[`${token0[2]}-${gas[2]}`] = [
                res0,
                res1,
                pairAddr
            ]
        }
        if ( token0[2] === wGas[2] ) {
            _lpPrice[`GAS-${token1[2]}`] = [
                res0,
                res1,
                pairAddr
            ]
            _lpPrice[`${token1[2]}-GAS`] = [
                res1,
                res0,
                pairAddr
            ]
            _lpPrice[`${gas[2]}-${token1[2]}`] = [
                res0,
                res1,
                pairAddr
            ]
            _lpPrice[`${token1[2]}-${gas[2]}`] = [
                res1,
                res0,
                pairAddr
            ]
        }
    }
    // console.log(
    //     _lpPrice
    // )
    // get lp res: tokenNames : [tName0, tName1, ...]
    // console.log(
    //     lpPairCon," lpPairCon"
    // )
    // tokenNames: [tName0, tName1, ...]
    const oracle = tokenNames => {
        let res0 = BN(1);
        let path = [];
        let paths = [];
        for(let i = 0; i < tokenNames.length - 1; i++) {
            const token0Name = tokenNames[i]
            const token1Name = tokenNames[i + 1]
            const key = `${token0Name}-${token1Name}`
            const lp = _lpPrice[key]
            
            if ( !lp ) {
                return {
                    price: res0.toString(10),
                    lp: path,
                    paths
                }
            }
            if (!(lp instanceof Array)) {
                return {
                    price: res0.toString(10),
                    lp: path,
                    paths
                }
            }
            const [reserve0, reserve1, lpAddr] = lp
            paths.push(lpAddr)
            res0 = res0.mul(reserve1).div(reserve0)
            path.push([reserve0, reserve1])
        }
        return {
            price: res0.toString(10),
            lp: path,
            paths
        }
    }
    
    const getOutAmount = (inAmount, paths, slippage = 0.005) => {
        inAmount = BN(inAmount);
        for(let i = 0; i < paths.length - 1; i++) {
            const token0Name = paths[i]
            const token1Name = paths[i + 1]
            const key = `${token0Name}-${token1Name}`
            const lp = _lpPrice[key]
            if ( !lp ) {
                throw new Error('no lp')
            }
            if (!(lp instanceof Array)) {
                return inAmount.mul(lp).toString(10)
            }
            const [resIn, resOut] = lp
            inAmount = BN(getAmountOut(inAmount, resIn, resOut, slippage))
        }
        return inAmount.toString(10)
    }
    
    return {
        oracle,
        balance,
        getOutAmount,
        gasName: gas[2]
    }
}


function useUserInfo() {
    const {account, getBlockNumber} = useWeb3()
    const [data, setData] = useState(INIT)
    const { open } = useToast()

    const copyAddress = () => {
        copy(data.gooseAddress)
        open('copied')
    }

    const copyLink = () => {
        // copy(data.link)
        copy(
            window.location.origin + '/#/?r=' + account
        )
        open('copied')
    }

    const blockNumber = getBlockNumber()
    useEffect(() => {
        if ( blockNumber > 0 && account !== PROXY_ZERO_ADDRESS) {
            initAsyncData(() => init(account), setData)
        }
    }, [account, blockNumber])
    return {
        ...data,
        copyAddress,
        copyLink
    }
}

function UserProvider({ children }) {
    const data = useUserInfo()
    return (
        <Context.Provider value={data}>
            {children}
        </Context.Provider>
    )
}

export default UserProvider

export const useUser = () => useContext(Context)