// Relation
import { useEffect, useMemo, useState } from "react";

// import copy from 'copy-to-clipboard'

import {
    Relation,
    Team
} from "../../contract/contract";

import { useWeb3, initWeb3, BN, multiCalls, SendOn, utils, ZERO_ADDRESS , SendLocalOn} from '../../web3'


import {getUrlParams} from '../../utils'

import useBalance from './useBalance'


import initAsyncData from '../initAsyncData'

import useInput from '../useInput'
import useButton from "./useButton"


const INIT = {
    isRegisted: false,
    recommender: ZERO_ADDRESS,
}

let regList = {}
// if change chainId, clear regList
async function getRelation(account) {
    const relation = Relation()
    if (regList[account]) {
        return {
            recommender: regList[account],
            isRegisted: true
        }
    }
    const recommender = await relation.methods.introducer(account).call()
    const isRegisted = recommender !== ZERO_ADDRESS
    if ( isRegisted ) {
        regList[account] = recommender
    }
    return {
        recommender,
        isRegisted
    }

    // return INIT
}


export function useRelation() {
    const {account, getBlockNumber, chainId} = useWeb3()
    const [data, setData] = useState(INIT)
    const blockNubmer = getBlockNumber()

    useEffect(() => {
        // console.log({account, blockNubmer})
        initAsyncData(() => getRelation(account), setData)
    },[account, blockNubmer])

    useEffect(() => {
        regList = {}
    }, [chainId])
    return {
        ...data,
        // copyLink
    }
}

export function useRegist() {
    const recommender = useInput('')

    useEffect(() => {
        const {r} = getUrlParams()
        if (r) {
            recommender.onChange(r)
        }
    },[])

    const button = useButton('Join', {
        send() {
            const relation = Relation()
            return relation.methods.bind(recommender.value)
        }
    })
    return {
        button,
        recommender
    }
}


const INIT_TEAM = {
    team: [],
}

async function getTeam(account) {
    // const team = Team()
    const team = Team()
    const calls = await team.methods.getMyTeam(account).call()
    // console.log({calls})

    return {
        team: calls.map(v => ({
            addr: v.user,
            myUSDT: BN(v.stakedUSDT).div(1e18).toString(10),
            teamUSDT: BN(v.teamUSDT).add(v.stakedUSDT).div(1e18).toString(10),
        }))
    }
}

export function useTeam() {
    const {account, getBlockNumber} = useWeb3()
    const [data, setData] = useState(INIT_TEAM)
    const blockNubmer = getBlockNumber()

    useEffect(() => {
        // console.log({account, blockNubmer})
        initAsyncData(() => getTeam(account), setData)
    },[account, blockNubmer])

    return {
        ...data,
        // copyLink
    }
}