

import {
    Container,
    Card,
    TextGL,
    WhiteSpace,
    Text,
    Button,
    TextLinear,
    Input
} from '../../components'


function Theme() {
    return (
        <Container>
            <TextLinear>TextLinear</TextLinear>
            <WhiteSpace />
            <TextGL>Card</TextGL>
            <WhiteSpace />
            {
                [0,1,2,3,4,5,6, 7, 8].map(v => (
                    <>
                        <WhiteSpace />
                        <Card key={v} decorate={v}>
                            <TextGL>{v}</TextGL>
                            <br />
                            <Input
                                input={{
                                    value: '12323'
                                }}
                            />
                        </Card>
                    </>
                ))
            }
            <WhiteSpace />
            <TextGL>Text</TextGL>
            <WhiteSpace />

            {
                [0,1,2,3,4,5,6].map(v => <Text key={v} size={v} color={v}>{v}</Text>)
            }
            <WhiteSpace />
            <TextGL>Button</TextGL>
            <WhiteSpace />

            {
                [0,1,2,3,4,5,6].map(v => (
                    <>
                        <WhiteSpace />
                        <Button shadow key={v} size={v+''} status={v+''}>Button {v+''}</Button>
                    </>
                ))
            }
            <WhiteSpace />
        </Container>
    )
}

export default Theme