
// import { memo } from 'react';

import { useTranslation } from 'react-i18next';

// import styled from 'styled-components';

import {
    // HashRouter as Router,
    // Route,
    // Link,
    useHistory
} from "react-router-dom";

import {
    Card,
    Container,
    TextMD,
    TextM,
    TextSM,
    TextGL,
    WhiteSpace,
    // Input,
    Button,
    Hr,
    Icon,
    TextLinear,
    FlexBlock,
    // Progress,
    // Hot,
    Tooltip,
    // APR,
    NoData,
    Hot,
    ImgIcon
} from '../../components'


// import StakeSeed from './StakeSeed'
// import StakeDialog from '../StakeDialog'

import useBalance from '../../hook/pages/useBalance'

// import {
//     useInitLiquidity,
//     useInitPurchase,
//     useLpClaim
// } from '../../hook/pages/usePurchase'
// dialog 
// import useDialog, {useDialogContext, Dialog2} from '../../hook/useDialog'
// import useInput from '../../hook/useInput'

import useCopy from '../../hook/useCopy'

import { wFormatInt, splitNumber, BN } from 'web3-sam/dist/BigNumber';
// import {utils} from 'web3-sam'
// import {getAmountIn} from "../../utils/swap"

import Regist from '../Regist'
import {NodeData} from '../Node'

import {LpPoolImport as LpPool} from '../LpPool'


// import SvgCircle,{CircleArr} from '../../pageComponents/SvgCircle'

// import {useSmiCountdown} from '../../hook/useCountdown'

// import {
//     useStakeF,
//     useClaimF2,
//     useClaimF1
// } from '../../hook/pages/useStakeF'

// const { shortAddress } = utils

// function ClaimF1() {
//     return <Button size='0' {...useClaimF1()}/>
// }
// const ClaimF1Mome = memo(ClaimF1)

// function ClaimF2() {
//     return <Button size='0' {...useClaimF2()}/>
// }
// const ClaimF2Mome = memo(ClaimF2)

function Home() {
    const bal = useBalance()
    const oracle = bal.oracle(["CDA","USDT"])
    // const {integer, decimalTail, decimal0} = splitNumber(oracle.price)
    const history = useHistory()
    const copy = useCopy()
    const {t} = useTranslation()
    return (
        <Container>
            <FlexBlock flex>
                <div style={{textAlign: 'left'}}>
                    <TextGL b='800'>{t("Cake")} {t("DAO")}</TextGL>
                    <br />
                    <TextSM color='2'>{t("Joint mining to earn Cake.")}</TextSM>
                </div>
                <div>
                    <Button style={{marginRight:'8px'}} status='2' size='0' onClick={bal.copyLink}>{t("Share Link")}</Button>
                </div>
            </FlexBlock>
            <WhiteSpace size='2' />

            <Regist />
            <WhiteSpace/>
            <LpPool />
            <WhiteSpace/>
            <Card>
                <FlexBlock flex>
                    <div style={{textAlign: 'left'}}>
                        {/* <Tooltip
                            title={
                                <>
                                    <TextSM style={{marginBottom: "6px"}}>HABIBI {wFormatInt(oracle.lp[0][0])}</TextSM>
                                    <br />
                                    <TextSM>USDT {wFormatInt(oracle.lp[0][1])}</TextSM>
                                </>
                            }
                            placement="right"
                            arrow
                        >
                            <TextSM color='1'>FY / USDT <Icon size='12' type='icon-info-fill' /></TextSM>
                        </Tooltip>  */}
                        {/* <TextLinear size='0'>FY / USDT</TextLinear> */}
                        <TextM color='0' b='800'>CDA {t("Price")}</TextM>
                        <br />
                        {/* <TextLinear size='3'>{wFormatInt(integer)}.{decimal0 !== 0 ? <sup style={{fontSize: '20px'}}>{decimal0}</sup> : null }{(decimalTail.slice(0,4)*1)}</TextLinear> */}
                        {/* <TextLinear size='3'>{integer*1 === 0 ? 0 : wFormatInt(integer)}.{decimal0 !== 0 ? <sup style={{fontSize: '20px'}}>{decimal0}</sup> : null }{(decimalTail.slice(0,4)*1)}</TextLinear> <TextSM color='2'> / USDT</TextSM> */}
                        <TextLinear size='2'>{Math.floor(oracle.price * 100) / 100}</TextLinear> <TextSM color='2'> / USDT</TextSM>
                    </div>
                    <div style={{textAlign: 'right'}}>
                        <Button style={{marginRight:'0px'}} status='2' size='1' onClick={() => history.push('/swap')}>{t("Swap")}</Button>
                    </div>
                </FlexBlock>
                <TextSM color='2' onClick={() => copy("0x53183aDcBD02c2Ad5cf23Cef1F23dCa21ee33069")}>Contract : 0x53183aDc...Ca21ee33069 <ImgIcon src='./icon/copy.png' size='16'/></TextSM>
            
            </Card>
            {/* </Regist> */}
            <WhiteSpace />
            <NodeData />
        </Container>
    )
}
export default Home



// const LinkA = styled.a`
//     display: block;
//     padding: 4px;
//     margin-right: 2px;
//     opacity: 0.6;
// `