// Relation
import { useEffect, useMemo, useState } from "react";

// import copy from 'copy-to-clipboard'

import {
    ComNode,
    SuperNode,
    TokenAll,
    CakeBuyerAddress
} from "../../contract/contract";

import { useWeb3, initWeb3, BN, multiCalls, ERC20, utils, ZERO_ADDRESS , SendLocalOn} from '../../web3'


// import {getUrlParams} from '../../utils'

// import useBalance from './useBalance'


import initAsyncData from '../initAsyncData'

// import useInput from '../useInput'
import useButton from "./useButton"


const INIT = {
    commnuity: {
        balances: 0,
        applied: false,
        claimCDA: 0,
        claimCAKE: 0,
        totalClaimedCDA: 0,
        totalClaimedCAKE: 0,
    },
    supers: {
        balances: 0,
        applied: false,
        claimCDA: 0,
        claimCAKE: 0,
        totalClaimedCDA: 0,
        totalClaimedCAKE: 0,
    }
}
// function claimsOfCDA(address) external view returns(Claim memory);
// function claimsOfCAKE(address) external view returns(Claim memory);
// function claimFor(address) external returns(uint cda, uint cake);
// if change chainId, clear regList
async function getInit(account) {
    const comNode = ComNode()
    const superNode = SuperNode()
    const calls = await multiCalls({
        commnuity: {
            balances: comNode.methods.balances(account),
            claim: comNode.methods.claimFor(account),
            totalClaimedCDA: comNode.methods.claimsOfCDA(account),
            totalClaimedCAKE: comNode.methods.claimsOfCAKE(account),
        },
        super: {
            balances: superNode.methods.balances(account),
            claim: superNode.methods.claimFor(account),
            totalClaimedCDA: superNode.methods.claimsOfCDA(account),
            totalClaimedCAKE: superNode.methods.claimsOfCAKE(account),
        }
    })
    
    const cBalance = BN(calls.commnuity.balances).div(1e18).toString()
    const sBalance = BN(calls.super.balances).div(1e18).toString()

    function showEmpty(str) {
        return str === '0' ? '0.00' : str
    }

    const cClaimCake = showEmpty(BN(calls.commnuity.claim[1]).div(1e18).dp(6,1).toString())
    const cClaimCDA = showEmpty(BN(calls.commnuity.claim[0]).div(1e18).dp(6,1).toString())
    const cTotalClaimedCDA = showEmpty(BN(calls.commnuity.totalClaimedCDA.totalCalim).div(1e18).dp(6,1).toString())
    const cTotalClaimedCAKE = showEmpty(BN(calls.commnuity.totalClaimedCAKE.totalCalim).div(1e18).dp(6,1).toString())

    const sClaimCake = showEmpty(BN(calls.super.claim[1]).div(1e18).dp(6,1).toString())
    const sClaimCDA = showEmpty(BN(calls.super.claim[0]).div(1e18).dp(6,1).toString())
    const sTotalClaimedCDA = showEmpty(BN(calls.super.totalClaimedCDA.totalCalim).div(1e18).dp(6,1).toString())
    const sTotalClaimedCAKE = showEmpty(BN(calls.super.totalClaimedCAKE.totalCalim).div(1e18).dp(6,1).toString())

    const commnuity = {
        balances: cBalance,
        applied: cBalance*1 > 0,
        claimCDA: cClaimCDA,
        claimCAKE: cClaimCake,
        totalClaimedCDA: cTotalClaimedCDA,
        totalClaimedCAKE: cTotalClaimedCAKE,
    }
    const supers = {
        balances: sBalance,
        applied: sBalance*1 > 0,
        claimCDA: sClaimCDA,
        claimCAKE: sClaimCake,
        totalClaimedCDA: sTotalClaimedCDA,
        totalClaimedCAKE: sTotalClaimedCAKE
    }
    return {
        commnuity,
        supers
    }
    // return INIT
}


export function useNode() {
    const {account, getBlockNumber} = useWeb3()
    const [data, setData] = useState(INIT)
    const blockNubmer = getBlockNumber()

    useEffect(() => {
        // console.log({account, blockNubmer})
        initAsyncData(() => getInit(account), setData)
    },[account, blockNubmer])
    return {
        ...data,
        // copyLink
    }
}


export function useNodeApply() {

    const {
        sender,
        coins
    } = useMemo(() => {
        const {map: {USDT}} = TokenAll()
        const sender = SuperNode()._address
        const coins = [
            [...USDT, 10000, true]
        ]
        return {
            sender,
            coins
        }
    },[])
    
    const {
        cSender,
        cCoins
    } = useMemo(() => {
        const {map: {USDT}} = TokenAll()
        const sender = ComNode()._address
        const coins = [
            [...USDT, 10000, true]
        ]
        return {
            cSender:sender,
            cCoins: coins
        }
    },[])

    const cButton = useButton('Apply', {
        approve: {
            sender: cSender,
            coins: cCoins
        },
        send() {
            const node = ComNode()
            return node.methods.applyNode()
        }
    })

    const sButton = useButton('Apply', {
        approve: {
            sender,
            coins
        },
        send() {
            const node = SuperNode()
            return node.methods.applyNode()
        }
    })

    return {
        cButton,
        sButton
    }
}


const INIT_DATA = {
    comCake: 0,
    superCake: 0,
    buyerCake: 0,
    superCDA: 0,
    comCDA: 0
}

async function getInitData() {
    const tokenAll = TokenAll()
    const cake = ERC20(tokenAll.map.CAKE[0])
    const cda = ERC20(tokenAll.map.CDA[0])
    const comNode = ComNode()
    const superNode = SuperNode()
    const buyerAddress = CakeBuyerAddress()
    
    const calls = await multiCalls({
        com: cake.methods.balanceOf(comNode._address),
        super: cake.methods.balanceOf(superNode._address),
        buyer: cake.methods.balanceOf(buyerAddress),
        superCDA: cda.methods.balanceOf(superNode._address),
        comCDA: cda.methods.balanceOf(comNode._address),
    })

    const comCake = BN(calls.com).div(1e18).toString()
    const superCake = BN(calls.super).div(1e18).toString()
    const buyerCake = BN(calls.buyer).div(1e18).toString()
    const superCDA = BN(calls.superCDA).div(1e18).toString()
    const comCDA = BN(calls.comCDA).div(1e18).toString()
    // comCake: 0,
    // superCake: 0,
    // buyerCake: 0
    return {
        comCake,
        superCake,
        buyerCake,
        comCDA,
        superCDA
    }
}


export function useNodeData() {
    const {account, getBlockNumber} = useWeb3()
    const [data, setData] = useState(INIT_DATA)
    const blockNubmer = getBlockNumber()

    useEffect(() => {
        // console.log({account, blockNubmer})
        initAsyncData(() => getInitData(account), setData)
    },[account, blockNubmer])
    return {
        ...data,
        // copyLink
    }
}


export function useClaimCom() {
    const {account} = useWeb3()
    const cButton = useButton('Claim', {
        send() {
            const con = ComNode()
            return con.methods.claimFor(account)
        },
    })

    return cButton
}

export function useClaimSuper() {
    const {account} = useWeb3()
    const cButton = useButton('Claim', {
        send() {
            const con = SuperNode()
            return con.methods.claimFor(account)
        },
    })

    return cButton
}
