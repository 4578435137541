// import { memo } from 'react';
import { useTranslation } from 'react-i18next';
// import styled from 'styled-components';

// import {
//     // HashRouter as Router,
//     // Route,
//     // Link,
//     useHistory
// } from "react-router-dom";

import {
    Card,
    Container,
    TextMD,
    TextM,
    TextSM,
    TextGL,
    WhiteSpace,
    ImgIcon,
    Input,
    Button,
    Hr,
    Icon,
    TextLinear,
    FlexBlock,
    // Progress,
    // Hot,
    Tooltip,
    // APR,
    NoData,
    Hot,
    TipsImg
} from '../../components'


import useBalance from '../../hook/pages/useBalance'

// dialog 
// import useDialog, {useDialogContext, Dialog2} from '../../hook/useDialog'
// import useInput from '../../hook/useInput'
import useCopy from '../../hook/useCopy'

import {useTeam} from '../../hook/pages/useRelation'

import { wFormatInt, splitNumber, BN } from 'web3-sam/dist/BigNumber';
import {utils} from 'web3-sam'
// import {getAmountIn} from "../../utils/swap"
const { shortAddress } = utils

function Me() {
    const copy = useCopy()
    const {team} = useTeam()
    const {t} = useTranslation();
    return (
        <Container align='left'>
            <TextM b='800'><ImgIcon src='./icon/team.png'/> {t("My")} {t("Team")}</TextM>
            <WhiteSpace />
            {   
                team.length === 0 ? <Card><NoData /></Card> :
                team.map((v) => (
                    <Card key={v.addr} style={{marginBottom: '12px'}}>
                        <TextSM onClick={() => copy(v.addr)}>{shortAddress(v.addr, 10)} <ImgIcon src='./icon/copy.png' size='16'/></TextSM>
                        <WhiteSpace size='0'/>
                        <FlexBlock flex>
                            <TextSM color='2'>{t("My")} {t("Power")} </TextSM>
                            <TextLinear size='0'>{wFormatInt(v.myUSDT)}</TextLinear>
                            {/* <TextSM><TextLinear size='0'>0</TextLinear> U</TextSM> */}
                        </FlexBlock>
                        {/* <WhiteSpace /> */}
                        <Hr style={{margin:'6px 0px'}}/>
                        {/* <WhiteSpace /> */}
                        <FlexBlock flex>
                            {/* incloud my power */}
                            <TextSM color='2'>{t("Total")} {t("Power")}</TextSM>
                            <TextLinear size='0'>{wFormatInt(v.teamUSDT)}</TextLinear>
                            {/* <TextSM><TextLinear size='0'>0</TextLinear> U</TextSM> */}
                        </FlexBlock>
                    </Card>       
                ))
            }
            
        </Container>
    )
}

export default Me;

