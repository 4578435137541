
import { memo } from 'react';

// import { useTranslation } from 'react-i18next';

import styled from 'styled-components';

import {
    // HashRouter as Router,
    // Route,
    // Link,
    useHistory
} from "react-router-dom";

import {
    Card,
    Container,
    TextMD,
    TextM,
    TextSM,
    TextGL,
    WhiteSpace,
    Input,
    ImgIcon,
    Button,
    Hr,
    HrCol,
    Icon,
    TextLinear,
    FlexBlock,
    // Progress,
    // Hot,
    Tooltip,
    // APR,
    NoData,
    Hot,
    TipsImg,
    ChooseButtons
} from '../../components'

import useBalance from '../../hook/pages/useBalance'
import {
    useStakeLp,
    useShareBoost
} from '../../hook/pages/useLpPool'

// dialog 
import useDialog, {useDialogContext, Dialog2} from '../../hook/useDialog'
// import useInput from '../../hook/useInput'

// import useCopy from '../../hook/useCopy'

import { wFormatInt, splitNumber, BN } from 'web3-sam/dist/BigNumber';
import {utils} from 'web3-sam'

import Approve from '../../contractComponents/Approve';

// function ClaimF1() {
//     return <Button size='0' {...useClaimF1()}/>
// }
// const ClaimF1Mome = memo(ClaimF1)

// function ClaimF2() {
//     return <Button size='0' {...useClaimF2()}/>
// }
// const ClaimF2Mome = memo(ClaimF2)

function getBoost(num) {
    num = num * 1
    if ( num >= 10000 ) return 2;
    else if ( num >= 6000 ) return 1.8;
    else if ( num >= 2000 ) return 1.5;
    else return 1;
}
function PurchaseLpPool({close}) {
    const bal = useBalance()
    const {
        stakeUsdt,
        cButton
    } = useStakeLp({onSuccess:close})

    const minDisabled = stakeUsdt.value < 500
    const insufficient = stakeUsdt.value*1 > bal.balance.USDT * 1

    const setMax = () => stakeUsdt.onChange(bal.balance.USDT)
    return (
        <Card>
            <TextM b='800'>Buy Power</TextM>
            <WhiteSpace />
            <Hr />
            <WhiteSpace />
            <TextSM color='2'>Availiable <TextLinear size='1'>{wFormatInt(bal.balance.USDT)}</TextLinear> USDT</TextSM>
            <WhiteSpace style={{height:'4px'}}/>
            <ChooseButtons
                size='5'
                // value='2000'
                {...stakeUsdt}
                list={[
                    {id: "500", title: "500"},
                    {id: "2000", title: "2000"},
                    {id: "6000", title: "6000"},
                    {id: "10000", title: "10000"},
                ]}
            />
            <WhiteSpace />
            {/* <WhiteSpace /> */}
            {/* <TextSM>Balance <TextLinear size='0'>0.0</TextLinear> U</TextSM> */}
            {/* <WhiteSpace style={{height:'4px'}}/> */}
            <Input
                after={<Button size='0' status='8' onClick={setMax}>Max</Button>}
                input={{
                    placeholder: 'Minimum 500',
                    ...stakeUsdt
                }}
            />
            <WhiteSpace />
            <FlexBlock flex>
                <TextSM color='2'>Boost</TextSM>
                <TextSM>x{getBoost(stakeUsdt.value)}</TextSM>
            </FlexBlock>
            <WhiteSpace />
            {
                minDisabled && <>
                    <TextSM style={{color:'red'}}>Minimum 500 USDT.</TextSM>
                    <WhiteSpace />
                </>
            }
            {
                insufficient ?
                <Button w='100' disabled> USDT Balance Insufficient</Button>:
                <Approve w='100' status='1' {...cButton} disabled={minDisabled} />
            }
            
        </Card>
    )
}

export function usePurchaseLpPool() {
    const {open, close} = useDialog()
    return () => open(<PurchaseLpPool close={close}/>)
}


function Burn({close}) {
    const {
        disabledUSDTBalance,
        disabledCDABalance,
        disabledFull,
        list,
        burnUsdt,
        burnU,
        burnCDA,
        bButton,
        cButton
    } = useShareBoost({onSuccess:close})
    return (
        <Card>
            <TextM b='800'>Burn</TextM>
            <WhiteSpace />
            <Hr />
            <WhiteSpace />
            <TextSM>Choose Boost</TextSM>
            <WhiteSpace />
            <ChooseButtons
                size='5'
                list={list}
                {...burnUsdt}
            />
            <WhiteSpace />
            <TextSM color='0'>Need <TextLinear size='0'>{burnU}</TextLinear> USDT or <TextLinear size='0' linear='1'>{wFormatInt(burnCDA)}</TextLinear> CDA</TextSM>
            <WhiteSpace />
            {
                disabledFull ?
                <Button w='100' disabled> Boost Full</Button>:
                <FlexBlock flex>
                    {
                        disabledUSDTBalance ?
                        <Button w='48' disabled> USDT Insufficient</Button>:
                        <Approve w='48' status='1' {...bButton} />
                    }
                    {
                        disabledCDABalance ?
                        <Button w='48' disabled> CDA Insufficient</Button>:
                        <Approve w='48' status='7' {...cButton} />
                    }
                </FlexBlock>
            }
        </Card>
    )
}

export function useBrun() {
    const {open, close} = useDialog()
    return () => open(<Burn close={close}/>)
}