
// import { memo } from 'react';

import { useTranslation } from 'react-i18next';

// import styled from 'styled-components';

import {
    // HashRouter as Router,
    // Route,
    // Link,
    useHistory
} from "react-router-dom";

import {
    Card,
    Container,
    TextMD,
    TextM,
    TextSM,
    TextGL,
    WhiteSpace,
    WingBlank,
    Input,
    Button,
    ImgIcon,
    Hr,
    Icon,
    TextLinear,
    FlexBlock,
    // Progress,
    // Hot,
    Tooltip,
    // APR,
    NoData,
    Hot,
    TipsImg
} from '../../components'


// import StakeSeed from './StakeSeed'
// import StakeDialog from '../StakeDialog'
// import Swap, {AddLiquidity} from './Swap'

import {
    useNode,
    useNodeApply,
    useNodeData,
    useClaimSuper,
    useClaimCom
} from '../../hook/pages/useNode'

import useBalance from '../../hook/pages/useBalance'
// dialog 
// import useDialog, {useDialogContext, Dialog2} from '../../hook/useDialog'
// import useInput from '../../hook/useInput'

// import useCopy from '../../hook/useCopy'

import { wFormatInt, splitNumber, BN } from 'web3-sam/dist/BigNumber';
// import {utils} from 'web3-sam'
// import {getAmountIn} from "../../utils/swap"

import Approve from "../../contractComponents/Approve"

// import SvgCircle,{CircleArr} from '../../pageComponents/SvgCircle'

// import {useSmiCountdown} from '../../hook/useCountdown'

// import {
//     useStakeF,
//     useClaimF2,
//     useClaimF1
// } from '../../hook/pages/useStakeF'

// const { shortAddress } = utils

// function ClaimF1() {
//     return <Button size='0' {...useClaimF1()}/>
// }
// const ClaimF1Mome = memo(ClaimF1)

// function ClaimF2() {
//     return <Button size='0' {...useClaimF2()}/>
// }
// const ClaimF2Mome = memo(ClaimF2)

function Node() {
    // const bal = useBalance()
    const {
        commnuity,
        supers
    } = useNode()
    const {
        cButton,
        sButton
    } = useNodeApply()
    const {t} = useTranslation();
    return (
        <Container align='left'>
            <TextMD b='800'><ImgIcon src='./icon/node1.png'/> {t("Node")}</TextMD>
            <WhiteSpace />
            <NodeData />
            <WhiteSpace />
            <Card align='center'>
                <TextMD b='800'>{t("Apply")} {t("Node")}</TextMD>
                <br />
                <TextSM color='2' style={{textAlign: 'center'}}>{t("Participating in the nodes, and enjoying platform-wide dividends.")}</TextSM>
                {
                    !commnuity.applied &&
                    <>
                        <WhiteSpace />
                        <Card align='center' decorate='9' noLogo>
                            <TextSM>{t("Community")} {t("Node")}</TextSM>
                            <br />
                            <TextLinear size='2'>3000 U</TextLinear>
                            <WhiteSpace />
                            {/* {
                                commnuity.applied ? <Button size='1' status='0' w='80' disabled>Applied</Button> : <Approve size='1' status='2' w='80' {...cButton} />
                            } */}
                            <Approve size='1' status='2' w='80' {...cButton} />
                        </Card>
                        
                    </>
                }

                {
                    !supers.applied &&
                    <>
                        <WhiteSpace />
                        <Card align='center' decorate='9' noLogo>
                            <TextSM>{t("Alliance")} {t("Node")}</TextSM>
                            <br />
                            <TextLinear size='2'>5000 U</TextLinear>
                            <WhiteSpace />
                            {/* {
                                supers.applied ? <Button size='1' status='0' w='80' disabled>Applied</Button> : <Approve size='1' status='0' w='80' {...sButton}/>
                            } */}
                            <Approve size='1' status='0' w='80' {...sButton}/>
                        </Card>
                    </>
                }
                {/* <FlexBlock flex>
                    <Card align='center' decorate='9' noLogo>
                        <TextSM>Community</TextSM>
                        <br />
                        <TextLinear size='1'>3000 U</TextLinear>
                        <WhiteSpace />
                        {
                            commnuity.applied ? <Button size='0' status='0' w='80' disabled>Applied</Button> : <Approve size='0' status='2' w='80' {...cButton} />
                        }
                    </Card>
                    <WingBlank />
                    <Card align='center' decorate='9' noLogo>
                        <TextSM>Super</TextSM>
                        <br />
                        <TextLinear size='1'>5000 U</TextLinear>
                        <WhiteSpace />
                        {
                            supers.applied ? <Button size='0' status='0' w='80' disabled>Applied</Button> : <Approve size='0' status='0' w='80' {...sButton}/>
                        }
                        
                    </Card>
                </FlexBlock> */}
                <WhiteSpace />
            </Card>
            {
                commnuity.applied &&
                <>
                    <WhiteSpace />
                    <NodeCom bal={commnuity} />
                </>
            }

            {
                supers.applied &&
                <>
                    <WhiteSpace />
                    <NodeAlliance bal={supers} />
                </>
            }
        </Container>
    )
}
export default Node

export function NodeData() {
    const {
        comCake,
        superCake,
        buyerCake,
        comCDA,
        superCDA
    } = useNodeData()
    const bal = useBalance()
    const oracle = bal.oracle(["CAKE","USDT"])
    const oracleCDA = bal.oracle(["CDA","USDT"])
    const cdaPrice = oracleCDA.price
    // console.log(cdaPrice, " price")
    const cakePrice = oracle.price
    const history = useHistory()
    const {t} = useTranslation()

    // console.log(history.location.pathname !== '/')
    return (
        <>
        {
            history.location.pathname === '/' && 
            <>
                <Card align='center'>
                    <TextMD b='800'>
                        {/* <ImgIcon src='./icon/safeBox.png'/> */}
                    {t("Cake")} {t("Safe")} {t("Box")}</TextMD>
                    <br />
                    <TextLinear size='2'>{wFormatInt(buyerCake)}</TextLinear> <TextSM color='2'> / Cake</TextSM>
                    <br />
                    <TextSM color='2'>≈ $ {wFormatInt(buyerCake * cakePrice + '')}</TextSM>
                    {/* <br /> */}
                </Card>
                <WhiteSpace />
            </>
        }
        <Card>
            <TextMD b='800'>{/*<ImgIcon src='./icon/safeBox.png'/>*/} {t("Node")} {t("Plan")}</TextMD>
            <br />
            <TextSM color='2'>{t("Apply Node to earn CAKE and CDA.")}</TextSM>
            <WhiteSpace />
            <Hr />
            <WhiteSpace />
            <TextM color='3' b='800'>{t("Community")} {t("Node")}</TextM>
            <br />
            <TextM>{wFormatInt(comCake)} <TextSM color='2'> / Cake ≈ $ {wFormatInt(comCake * cakePrice + '')}</TextSM></TextM>
            <br />
            <TextM>{wFormatInt(comCDA)} <TextSM color='2'> / CDA ≈ $ {wFormatInt(comCDA * cdaPrice + '')}</TextSM></TextM>
            <WhiteSpace />
            <TextM color='3' b='800'>{t("Alliance")} {t("Node")}</TextM>
            <br />
            <TextM>{wFormatInt(superCake)} <TextSM color='2'> / Cake ≈ $ {wFormatInt(superCake * cakePrice + '')}</TextSM></TextM>
            <br />
            <TextM>{wFormatInt(superCDA)} <TextSM color='2'> / CDA ≈ $ {wFormatInt(superCDA * cdaPrice + '')}</TextSM></TextM>
            <WhiteSpace />
            {
                history.location.pathname === '/' && <Button size='1' status='2' w='100' onClick={() => history.push('/node')}>{t("Apply")} {t("Node")}</Button>
            }
            
        </Card>
        </>
        // <Card>
        //     <TextM b='800'><ImgIcon src='./icon/safeBox.png'/> Cake Safe Box</TextM>
        //     <br />
        //     <TextLinear size='3'>{wFormatInt(buyerCake)}</TextLinear> <TextSM color='2'>≈ $ {wFormatInt(buyerCake * cakePrice + '')}</TextSM>
        //     {/* <br /> */}
        //     <WhiteSpace />
        //     <Hr />
        //     <WhiteSpace />
        //     <TextSM>Community Cake</TextSM>
        //     <br />
        //     <TextM>{wFormatInt(comCake)} <TextSM color='2'>≈ $ {wFormatInt(comCake * cakePrice + '')}</TextSM></TextM>
        //     <WhiteSpace />
        //     <TextSM>Alliance Cake</TextSM>
        //     <br />
        //     <TextM>{wFormatInt(superCake)} <TextSM color='2'>≈ $ {wFormatInt(superCake * cakePrice + '')}</TextSM></TextM>
        // </Card>
    )
}

function ClaimCom() {
    return <Button size='1' {...useClaimCom()}/>
}

function ClaimSuper() {
    return <Button size='1' {...useClaimSuper()}/>
}



function NodeAlliance ({bal}) {
    const {t} = useTranslation();
    return (
        <Card>
            <TextM b='800'>{t("Alliance")} {t("Node")}</TextM>
            <WhiteSpace />
            <FlexBlock flex>
                <div>
                    <TextSM>{t("Node")} {t("Earn")}</TextSM>
                    <br />
                    <TextLinear size='0'>{bal.claimCAKE}</TextLinear><TextSM color='2' style={{marginLeft:'4px'}}> / CAKE</TextSM>
                    <br />
                    <TextLinear size='0'>{bal.claimCDA}</TextLinear><TextSM color='2' style={{marginLeft:'4px'}}> / CDA</TextSM>
                </div>
                {/* <ClaimMemo /> */}
                {/* <Button size='0' status='0'>Claim</Button>
                 */}
                <ClaimSuper />
            </FlexBlock>
            <WhiteSpace />
            <Hr />
            <WhiteSpace />
            <FlexBlock flex>
                <TextSM color='2'>{t("Total")} {t("Claimed")} CAKE</TextSM>
                <TextSM >{bal.totalClaimedCAKE}</TextSM>
            </FlexBlock>
            <FlexBlock flex>
                <TextSM color='2'>{t("Total")} {t("Claimed")} CDA</TextSM>
                <TextSM >{bal.totalClaimedCDA}</TextSM>
            </FlexBlock>
        </Card>
    )
}

function NodeCom ({bal}) {
    const {t} = useTranslation();
    return (
        <Card>
            <TextM b='800'>{t("Community")} {t("Node")} </TextM>
            <WhiteSpace />
            <FlexBlock flex>
                <div>
                    <TextSM>{t("Node")} {t("Earn")}</TextSM>
                    <br />
                    <TextLinear size='0'>{bal.claimCAKE}</TextLinear><TextSM color='2' style={{marginLeft:'4px'}}> / CAKE</TextSM>
                    <br />
                    <TextLinear size='0'>{bal.claimCDA}</TextLinear><TextSM color='2' style={{marginLeft:'4px'}}> / CDA</TextSM>
                </div>
                {/* <ClaimMemo /> */}
                {/* <Button size='0' status='0'>Claim</Button> */}
                <ClaimCom />
            </FlexBlock>
            <WhiteSpace />
            <Hr />
            <WhiteSpace />
            <FlexBlock flex>
                <TextSM color='2'>{t("Total")} {t("Claimed")} CAKE</TextSM>
                <TextSM >{bal.totalClaimedCAKE}</TextSM>
            </FlexBlock>
            <FlexBlock flex>
                <TextSM color='2'>{t("Total")} {t("Claimed")} CDA</TextSM>
                <TextSM >{bal.totalClaimedCDA}</TextSM>
            </FlexBlock>
        </Card>
    )
}