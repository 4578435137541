
import { memo } from 'react';

import { useTranslation } from 'react-i18next';

import styled from 'styled-components';

import {
    // HashRouter as Router,
    // Route,
    // Link,
    useHistory
} from "react-router-dom";

import {
    Card,
    Container,
    TextMD,
    TextM,
    TextSM,
    TextGL,
    WhiteSpace,
    WingBlank,
    Input,
    Button,
    ImgIcon,
    Hr,
    Icon,
    TextLinear,
    FlexBlock,
    Progress,
    // Hot,
    Tooltip,
    // APR,
    NoData,
    Hot,
    TipsImg
} from '../../components'


// import StakeSeed from './StakeSeed'
// import StakeDialog from '../StakeDialog'
// import Swap, {AddLiquidity} from './Swap'

import {
    useNode,
    useNodeApply
} from '../../hook/pages/useNode'

import useBalance from '../../hook/pages/useBalance'
// dialog 
import useDialog, {useDialogContext, Dialog2} from '../../hook/useDialog'
// import useInput from '../../hook/useInput'

// import useCopy from '../../hook/useCopy'

import { wFormatInt, splitNumber, BN } from 'web3-sam/dist/BigNumber';
import {utils} from 'web3-sam'
import {getAmountIn} from "../../utils/swap"

import Approve from "../../contractComponents/Approve"

// import SvgCircle,{CircleArr} from '../../pageComponents/SvgCircle'

// import {useSmiCountdown} from '../../hook/useCountdown'

// import {
//     useStakeF,
//     useClaimF2,
//     useClaimF1
// } from '../../hook/pages/useStakeF'

const { shortAddress } = utils

// function ClaimF1() {
//     return <Button size='0' {...useClaimF1()}/>
// }
// const ClaimF1Mome = memo(ClaimF1)

// function ClaimF2() {
//     return <Button size='0' {...useClaimF2()}/>
// }
// const ClaimF2Mome = memo(ClaimF2)


function CakePlan() {
    // const bal = useBalance()
    const {t} = useTranslation()
    return (
        <Container align='left'>
            <TextMD b='800'><ImgIcon src='./icon/node.png'/> {t("Cake")} {t("Plan")}</TextMD>
            <WhiteSpace />
            <Card>
                <TextMD b='800'>{t("Cake")} {t("Pool")}</TextMD>
                <br />
                <TextSM color='2' style={{textAlign: 'center'}}>{t("Stake CDA to earn Cake.")}</TextSM>
                <WhiteSpace />
                <Hr />
                <WhiteSpace />
                <FlexBlock flex>
                    <div>
                        <TextSM color='2' style={{textAlign: 'center'}}>{t("Release")} {t("Cake")}</TextSM>
                        <br />
                        <TextLinear size='2'>0.00</TextLinear><TextSM color='2' style={{marginLeft:'4px'}}> ≈ $ 0.00</TextSM>
                        <br />
                        <TextSM color='2'>{t("Total")} {t("Revenue")} $ <TextSM>0.00</TextSM></TextSM>
                    </div>
                    <Button status='0' size='1'>{t("Claim")}</Button>
                </FlexBlock>
                <WhiteSpace />
                <Hr />
                <WhiteSpace />
                <div style={{textAlign:'center'}}>
                    <TextSM>{t("Rewarding")} {t("Balance")}</TextSM>
                    <br />
                    <TextLinear size='2'>0.00</TextLinear> <TextSM color='2'>/ {t("Cake")}</TextSM>
                    <br />
                    <TextSM color='2'>≈ $ 0.00</TextSM>
                </div>
                <WhiteSpace />
                {/* <FlexBlock flex>
                    <TextSM color='2'>Rewarding <TextSM>0.00</TextSM> Cake</TextSM>
                    <TextSM color='2'>Total <TextSM>{1000}</TextSM> Cake</TextSM>
                </FlexBlock> */}
                <FlexBlock flex>
                    <TextSM color='2' style={{marginRight:'12px'}}>{t("Raised")} </TextSM> <Progress value={100}/> <TextSM style={{marginLeft:'12px'}}>{100}%</TextSM>
                </FlexBlock>
                <FlexBlock flex>
                    <TextSM color='2'>{t("Staked")} <TextSM>0.00</TextSM> CDA</TextSM>
                    <TextSM color='2'>{t("Total")} <TextSM>{1000}</TextSM> CDA</TextSM>
                </FlexBlock>
                <WhiteSpace />
                <Button w='100' status='1'>{t("Coming soon")}</Button>
                
            </Card>
            <WhiteSpace />
            <Card style={{display:'none'}}>
                <TextM b='800'>{t("Finished")}</TextM>
                <WhiteSpace />
                <Hr />
                <WhiteSpace />
                {
                    // [{id:2, rate: 100, day: 1}, {id:1, rate: 100, day: 90}].map(v => (
                    //     // <div key={v.id}>
                    //     //     <FlexBlock flex>
                    //     //     </FlexBlock>
                    //     // </div>
                    //     <div key={v.id} style={{marginBottom:'8px'}}>
                    //         <Hot style={{flex: 1}}># 0{v.id}</Hot>
                    //         <FlexBlock flex>
                    //             <div style={{flex: 8, margin:'0px 0px'}}>
                    //                 <FlexBlock flex>
                    //                     <TextSM color='2'>My Staked</TextSM>
                    //                     <TextSM color='2'><TextSM>0.00</TextSM> CDA</TextSM>
                    //                 </FlexBlock>
                    //                 <FlexBlock flex>
                    //                     <TextSM color='2'>Lock Time</TextSM>
                    //                     <TextSM color='2'><TextSM>1</TextSM> Day</TextSM>
                    //                 </FlexBlock>
                    //                 <FlexBlock flex>
                    //                     {/* not shrink */}
                    //                     <TextSM color='2'>Total Staked</TextSM>
                    //                     <TextSM color='2'><TextSM>0.00</TextSM> CDA</TextSM>
                    //                 </FlexBlock>
                    //                 <FlexBlock flex>
                    //                     <TextSM color='2'>My Reward</TextSM>
                    //                     <TextSM color='2'><TextSM>0.00</TextSM> Cake</TextSM>
                    //                 </FlexBlock>
                                    
                    //                 {/* <TextSM color='2'>Surplus <TextSM>100</TextSM> %</TextSM> */}
                    //             </div>
                    //             {/* <Button status='1' size='0' style={{flex: 2}}>Stake</Button> */}
                    //         </FlexBlock>
                    //         <WhiteSpace />
                    //         <Hr />
                    //     </div>
                    // ))
                }
                <TextM color='2' style={{display:'block', width: '100%', textAlign:'center'}}>{t("Coming soon")}</TextM>
                {/* <NoData /> */}
            </Card>
        </Container>
    )
}
export default CakePlan
