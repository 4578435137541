import styled from "styled-components"

// import { Icon } from '../../components'
import { useHistory } from "react-router-dom"

function Logo({ children, size, src, ...other }) {
    const history = useHistory()
    return (
        <OutBlock {...other} onClick={() => {
            history.push('/')
        }}>
            {/* <LogoImgWrap>
                <LogoImg src="./logo.svg" size={size} />
            </LogoImgWrap> */}
            <LogoImg size={size || '7'} src={src || "./homelogo.png"} />
            {children}
        </OutBlock>
    )
}

export default Logo

const default_w = '5'
const OutBlock = styled.div`
    display: flex;
    /* width: 100px; */
    justify-content: flex-start;
    align-items: center;
    /* background: linear-gradient(90deg, #F3503C 0%, #FE06F0 100%); */
    opacity: 1;
    /* border-radius: 90px; */
`
const LogoImgWrap = styled.div`
    padding: 5px;
    border-radius: 100px;
    border: 1px solid #ff9a32;
`
const LogoImg = styled.img`   
    width: ${p => p.size || default_w}rem;
    /* width: ${p => p.theme.size[p.size] || default_w}rem; */
    /* height: ${p => p.theme.size[p.size] || default_w}rem; */
    display: block;
    /* border-radius: 8px; */
    /* border: 2px solid #f4de9d82; */
    /* box-shadow: 0px 0px 16px #597358; */
`