import styled from 'styled-components'
// import CircularProgress from '@material-ui/core/CircularProgress';
// import { makeStyles } from '@material-ui/core/styles';

// const useStylesFacebook = makeStyles((theme) => ({
//   top: {
//     color: '#fdb756',
//     position: 'absolute',
//     top: '50%',
//     left: '50%',
//     marginLeft: '-20px',
//     marginTop: '-20px'
//   }
// }));

// import Rocket from '../IconFont/Rocket.js'

const ImgBagel = styled.img`
  /* width: 80px; */
  width: 50%;
  margin: auto;
  /* background-color: #fff; */
  /* border-radius: 1000px; */
  /* padding: 4px; */
  /* border: 3px solid rgba(255,255,255,.5); */
  /* border-radius: 4px; */
`

const ShowBlock = styled.div`
  animation: show .3s;
  @keyframes show {
    from {opacity: 0;}
    to {opacity: 1;}
  }
`

const LoadBlock = styled.div`
  /* position: absolute;
  width: 100%;
  height: 100%;
  background-color: #0A111D; */
  width: 100%;
  position: fixed;
  top: 35%;
  text-align: center;
  &:after {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    pointer-events: none;
    width: 100vw;
    height: 100vh;
    /* background: radial-gradient(50% 50% at 50% 50%,rgba(243,80,60,.05) 0,rgba(254,6,240,.02) 100%); */
    /* transform: translate(-50vw,-100vh); */
    z-index: -1;
    background: url(./background/back.jpg);
    background-size: 100% 100%;
  }
  /* margin-top: 25%; */
  /* left: 35%; */
  /* margin-top: -50px;
  margin-left: -50px; */
  /* text-align: center;
  animation-duration: 1s; // 一个完整动画的持续时间
  animation-iteration-count: infinite; // 动画循环次数：无限循环
  animation-name: heart-bounce; // 调用的动画名，对应上面的 .heart-bounce
  @keyframes heart-bounce {
    from {transform: scale(1); }
    12% {transform: scale(1.02); }
    20% {transform: scale(0.99); }
    28% {transform: scale(0.98); }
    32% {transform: scale(1.2); }
    38% {transform: scale(0.96); }
    50% {transform: scale(1); }
    58% {transform: scale(0.98); }
    70% {transform: scale(0.9); }
    80% {transform: scale(0.98); }
    to {transform: scale(1); }
  } */

`

function Loading() {
  // const classes = useStylesFacebook()
  // return <CircularProgress className={classes.top} disableShrink color="#fdb756" />
  return <ImgBagel src={process.env.LOADING}/>
}

export default function AwaitReady({children, ready=true}) {
  return ready? <ShowBlock>{children}</ShowBlock> :
    <LoadBlock>
      <Loading />
      {/* <Rocket /> */}
      {/* <Loader /> */}
    </LoadBlock>
}