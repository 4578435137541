// Relation
import { useEffect, useMemo, useState } from "react";

// import copy from 'copy-to-clipboard'

import {
    TokenAll,
    LpPool
} from "../../contract/contract";

import { useWeb3, initWeb3, BN, multiCalls, SendOn, utils, ZERO_ADDRESS , SendLocalOn} from '../../web3'

import useBalance from './useBalance'
import initAsyncData from '../initAsyncData'

import useInput from '../useInput'
import useButton from "./useButton"

const INIT = {
    daily: 100,
    claim: 0,
    revenue: 0, // total claimed value
    myPower: 0, // 不含 boost
    powerBoost: 0,
    powerInvest: 0, // 总投资金额
    share: 0, // 不含 boost
    shareBoost: 0,
    burnValue: 0,
    stakeList: []
}

// if change chainId, clear regList
async function getInit(account) {
    const lpPool = LpPool()
    // console.log(
    //     await lpPool.methods.claimFor(account).call()
    // )
    // try {
    //     console.log(
    //         lpPool._address
    //     )
    //     console.log(
    //         await lpPool.methods.blockTimeMulE8().call()
    //     )
    // } catch (error) {
    //     console.log(error)
    // }
    
    const calls = await multiCalls({
        timeE8: lpPool.methods.blockTimeMulE8(),
        userInfo: lpPool.methods.userInfo(account),
        claim: lpPool.methods.claimFor(account),
        stakeList: lpPool.methods.stakeListOf(account),
    })
    // calls.stakeList = []
    // console.log(
    //     calls.userInfo.claimedValue , " adfs"
    // )
    function showEmpty(str) {
        return str === '0' ? '0.00' : str
    }
    const claim = showEmpty(BN(calls.claim[0]).div(1e18).dp(6,1).toString(10))
    const userInfo = calls.claim[1]

    const timeE8 = calls.timeE8
    
    const revenue = BN(calls.userInfo.claimedValue).div(1e18).dp(6,1).toString(10)
    // const revenue = BN(userInfo.claimedValue).div(1e18).dp(6,1).toString(10)
    const myPower = BN(userInfo.power).div(timeE8).div(1e10).toString(10)

    // console.log(
    //     userInfo, " userInfo"
    // )
    const powerBoost = BN(userInfo.powerBoostE2).div(1e2).toString(10)
    const powerInvest = BN(userInfo.stakeUsdt).div(1e18).toString(10)
    const share = BN(userInfo.sharePower).div(timeE8).div(1e10).toString(10)
    const shareBoost = BN(userInfo.shareBoostE2).div(1e2).toString(10)
    // const 
    // console.log(
    //     calls,
    // )

    const now = Date.now()
    const day = 24*60*60
    // console.log(now)
    const stakeList = calls.stakeList.map((v,i) => {
        const stakeUsdt = BN(v.stakeUsdt).div(1e18).toString(10)
        const stakedDay = Math.floor((now/1000 - v.startTime) / day)
        const surplus = Math.floor(v.timeMulE8 * 10000 / timeE8) / 100
        const id = i + 1
        return {
            id,
            stakeUsdt,
            stakedDay,
            surplus
        }
    }).sort((a,b) => b.id - a.id)

    const burnValue = BN(userInfo.totalBurnValue).div(1e18).toString(10)
    return {
        daily: 100,
        claim,
        revenue, // total claimed value
        myPower, // 不含 boost
        powerBoost,
        powerInvest, // 总投资金额
        share, // 不含 boost
        shareBoost,
        stakeList,
        burnValue
    }
}

export function useLpPoolData() {
    const {account, getBlockNumber} = useWeb3()
    const [data, setData] = useState(INIT)
    const blockNubmer = getBlockNumber()

    useEffect(() => {
        // console.log({account, blockNubmer})
        initAsyncData(() => getInit(account), setData)
    },[account, blockNubmer])
    return {
        ...data,
    }
}


export function useStakeLp({onSuccess}) {

    const stakeUsdt = useInput('2000', {type: 'number'})

    const {
        sender,
        coins
    } = useMemo(() => {
        const {map: {USDT,CDA}} = TokenAll()
        const sender = LpPool()._address
        const coins = [
            [...USDT, 10000, true],
            [...CDA, 10000, true]
        ]
        return {
            sender,
            coins
        }
    },[])

    const cButton = useButton('Buy Power', {
        approve: {
            sender,
            coins
        },
        send() {
            const con = LpPool()
            const stakeWei = BN(stakeUsdt.value).mul(1e18).dp(0,1).toString(10)
            return con.methods.stake(
                stakeWei
            )
        },
        confirm() {
            onSuccess()
        }
    })

    return {
        stakeUsdt,
        cButton
    }
}

const ShareBoostList = [
    {id: "1.5", val: "100", title: "x1.5"},
    {id: "2", val: "500", title: "x2"},
    {id: "2.5", val: "1000", title: "x2.5"},
    {id: "3", val: "2000", title: "x3"},
]
const ShareBoostMap = {
    "1": "500",
    "1.5": "100",
    "2": "500",
    "2.5": "1000",
    "3": "2000"
}
export function useShareBoost({onSuccess}) {
    const {burnValue, shareBoost} = useLpPoolData()
    // console.log(burnValue === '0' ? 500 : burnValue * 2, " burnValue === '0' ? 500 : burnValue * 2")
    const burnUsdt = useInput("1", {type: 'number'})
    // console.log( burnUsdt , " burnUsdt")
    useEffect(() => {
        // burnUsdt.onChange(Math.floor(burnValue === '0' ? 500 : burnValue * 2) + '')
        burnUsdt.onChange(shareBoost * 1 + .5)
    },[shareBoost])
    const bal = useBalance()
    const oracle = bal.oracle(["CDA","USDT"])
    const price = oracle.price

    const burnU = ShareBoostMap[burnUsdt.value] - burnValue
    const burnCDA = burnU / price * 1.02
    const disabledUSDTBalance = bal.balance.USDT * 1 < burnU * 1
    const disabledCDABalance = bal.balance.CDA * 1 < burnCDA * 1
    const disabledFull = shareBoost * 1 >= 3
    // console.log(shareBoost, burnValue, " shareBoost")

    const {
        sender,
        coins
    } = useMemo(() => {
        const {map: {USDT,CDA}} = TokenAll()
        const sender = LpPool()._address
        const coins = [
            [...USDT, 10000, true],
            [...CDA, 10000, true]
        ]
        return {
            sender,
            coins
        }
    },[])

    const bButton = useButton('Burn USDT', {
        approve: {
            sender,
            coins
        },
        send() {
            const con = LpPool()
            const stakeWei = BN(burnU).mul(1e18).dp(0,1).toString(10)
            return con.methods.setShareBoost(
                stakeWei,
                0
            )
        },
        confirm() {
            onSuccess()
        }
    })

    const cButton = useButton('Burn CDA', {
        approve: {
            sender,
            coins
        },
        send() {
            const con = LpPool()
            const stakeWei = BN(burnCDA).mul(1e18).dp(0,1).toString(10)
            return con.methods.setShareBoost(
                0,
                stakeWei
            )
        },
        confirm() {
            onSuccess()
        }
    })

    const list = ShareBoostList.map(v => {
        return {
            ...v,
            disabled: burnValue * 1 >= v.val * 1
        }
    })

    return {
        disabledUSDTBalance,
        disabledCDABalance,
        disabledFull,
        list,
        burnUsdt,
        burnU,
        burnCDA,
        bButton,
        cButton
    }
}

export function useLpClaim() {
    const {account} = useWeb3()
    const cButton = useButton('Claim', {
        send() {
            const con = LpPool()
            return con.methods.claimFor(account)
        },
    })

    return cButton
}