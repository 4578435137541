
import {newContract} from "../web3";
import {getConstants} from './constants';

import I_LP from './ABI/LP.json'
import IFatory from './ABI/IFactory.json'

////// customize dapp ABI //////
import I_NODE from './ABI/INode.json'
import I_RELATION from './ABI/IRelations.json'
import I_TEAM from './ABI/ITeam.json'

import I_LP_POOL from './ABI/I_LP_POOL.json'

////// Native token //////
export const EthAddr = () => getConstants().ETH
export const WEthAddr = () => getConstants().WETH
export const Factorys = () => getConstants().ORACLE_FACTORYS
export const Oracle = () => getConstants().ORACLE
////// uesBalance //////
export const TokenAll = () => {
    const {
        BALANCE_LIST
    } = getConstants()
    const map = {}
    BALANCE_LIST.forEach(v => {
        map[v[2]] = v
    })
    return {
        list: BALANCE_LIST,
        map
    }
}


export const ChooseList = () => getConstants().BALANCE_LIST

// lp
export const Pair = address => newContract(address, I_LP)
export const Factory = address => newContract(address, IFatory)
////// token end //////

///// stake /////
// export const Stake = () => newContract(getConstants().STAKE, I_STAKE)
// export const Purchase = () => newContract(getConstants().PURCHASE, I_PURCHASE)
// export const Stock = () => newContract(getConstants().STOCK, I_STOCK)

export const Relation = () => newContract(getConstants().RELATION, I_RELATION)
export const ComNode = () => newContract(getConstants().COM_NODE, I_NODE)
export const SuperNode = () => newContract(getConstants().SUPER_NODE, I_NODE)

export const Team = () => newContract(getConstants().TEAM, I_TEAM)

export const LpPool = () => newContract(getConstants().LP_POOL, I_LP_POOL)

export const CakeBuyerAddress = () => getConstants().CAKE_BUYER